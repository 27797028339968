import axios from 'axios'
import constant from "../constants/constant";
export default {
  namespaced: true,

  state: {
    songs:[],
    addedSongs:[],
    currentWavesurfer:null,
    selectedSong:null,
    showStickyPlayer:false,
    isPlaying:false,
    volume:1,
    pagination:{},
    isLoading:true,
    totalTime:0,
    loadedSongs:0, //count of currently loaded songs
    categoryName:null,
    categorySlug:null,
    isSpecial:null,
    type:"", //what type mood, genre, tempo
    moods:[],
    genre:[],
    specialMoods:[],
    tempoLimits:{high:120,low:20},
    seekTo:null,
    currentIndex:0,
    loadMore:false
  },

  getters:{
    getLoadMore(state){
      return state.loadMore;
    },
    getCurrentIndex(state){
      return state.currentIndex;
    },
    getSeekTo(state){
      return state.seekTo;
    },
    getSelectedSong(state){
      return state.selectedSong;
    },
    getSongs(state){
      return state.songs;
    },
    getAddedSongs(state){
      return state.addedSongs;
    },
    getCurrentWaveSurfer(state){
      return state.currentWavesurfer;
    },
    getShowStickyPlayer(state){
      return state.showStickyPlayer;
    },
    getVolume(state){
      return state.volume;
    },
    getIsPlaying(state){
      return state.isPlaying;
    },
    getPagination(state){
      return state.pagination;
    },
    getIsLoading(state){
      return state.isLoading;
    },
    getTotalTime(state){
      return state.totalTime;
    },
    getLoadedSongs(state){
      return state.loadedSongs;
    },
    getIsSpecial(state){
      return state.isSpecial;
    },
    getCategorySlug(state){
      return state.categorySlug;
    },
    getCategoryName(state){
      return state.categoryName;
    },
    getType(state){
      return state.type;
    },
    getMoods(state){
      return state.moods;
    },
    getGenre(state){
      return state.genre;
    },
    getSpecialMoods(state){
      return state.specialMoods;
    },
    getTempoLimit(state){
      return state.tempoLimits;
    }
  },

  mutations: {
    SET_LOAD_MORE(state, loadMore) {
      state.loadMore = loadMore;
    },
    SET_CURRENT_INDEX(state, index) {
      state.currentIndex = index;
    },
    SET_WAVESURFER(state, wavesurfer) {
      state.currentWavesurfer = wavesurfer;
    },
    SET_SEEKTO(state, seekTo) {
      state.seekTo = seekTo;
    },
    SET_SELECTED_SONG(state, payload){
      console.trace('SET_SELECTED_SONG');
      state.selectedSong = payload;
    },
    SET_SONGS(state, payload){
      state.songs = payload;
    },
    SET_ADDED_SONGS(state, payload){
      state.addedSongs = payload;
    },
    SET_SHOW_STICKY_PLAYER(state, payload){
      state.showStickyPlayer = payload;
    },
    SET_VOLUME(state, payload){
      state.volume = payload;
    },
    UPDATE_SONGS_TIME(state,payload){
      state.songs = state.songs.map(song => {
        if(song.id == payload.id){
          song = payload;
        }
        return song;
      })
    },
    SET_IS_PLAYING(state, payload){
      // console.trace('Tracing myFunction call');
      // alert('SET_IS_PLAYING - ' + payload)
      state.isPlaying = payload;

    },
    SET_PAGINATION(state, payload){
      state.pagination = payload;
    },
    SET_IS_LOADING(state, payload){
      state.isLoading = payload;
    },
    SET_TOTAL_TIME(state, payload){
      state.totalTime = payload;
    },
    SET_LOADED_SONGS(state, payload){
      state.loadedSongs = payload;
    },
    SET_CATEGORY_NAME(state,payload){
      state.categoryName = payload;
    },
    SET_CATEGORY(state,payload){
      if(payload){
        console.log('SET_CATEGORY',payload);
        state.categoryName = payload.categoryName;
        state.categorySlug = payload.categorySlug;
        state.isSpecial = payload.isSpecial;
      }else{
        state.categoryName = null;
        state.categorySlug = null;
        state.isSpecial = null;
      }
    },
    SET_GENRE_INFO(state,payload){
      if(payload){
        state.categoryName = payload.genreName;
        state.categorySlug = payload.genreSlug;
        state.isSpecial = null;
      }else{
        state.categoryName = null;
        state.categorySlug = null;
        state.isSpecial = null;
      }
    },
    SET_TYPE(state,payload){
      state.type = payload;
    },
    SET_MOODS(state,payload){
      state.moods = payload;
    },
    SET_GENRE(state,payload){
      state.genre = payload;
    },
    SET_SPECIAL_MOODS(state,payload){
      state.specialMoods = payload;
    },
    SET_TEMPO_LIMIT(state,payload){
      state.tempoLimits.high = payload.high;
      state.tempoLimits.low = payload.low;
    },
  },
  actions: {
    async fetchSongs({commit,state},payload){
      console.log('state',state.songs);

      return axios
          .get(payload.url)
          //.get('http://localhost/api/playlist')
          .then(response => {
            console.log('response',response.data);
            let result = response.data.music;
            let songs = result?.data;
            let pagination = {
              current_page : result?.current_page,
              links: result?.links,
              next_page_url: result?.next_page_url,
              path: result?.path,
              per_page: result?.per_page,
              prev_page_url: result?.prev_page_url,
              to: result?.to,
              total: result?.total,
              last_page_url:result?.last_page_url
            };

            console.log('state....',state.songs);
            commit('SET_SONGS',songs);
            // songs = [...state.songs,songs]
            // commit('SET_SONGS',[...state.songs,...songs]);
            commit('SET_PAGINATION',pagination);
            commit('SET_TOTAL_TIME',response.data.total_duration);
            commit('SET_TYPE',response.data.type);
            if(response.data && response.data.type == 'Genre'){
              commit('SET_GENRE_INFO',response.data.type_info);
            }else{
              if(location.pathname == '/soundeffects'){
                commit('SET_CATEGORY_NAME','Sound Effects');
                commit('SET_TYPE',' ');
              }else{
                commit('SET_CATEGORY',response.data.type_info);
              }


            }
            return {status:true}
          })
          .catch(error => {
            console.log(error)
            return {status:false,error:error}
          });
    },
    async fetchAddedSongs({commit,state},payload){
      return axios
          .get(payload.url)
          //.get('http://localhost/api/playlist')
          .then(response => {
            console.log('response',response.data);
            let result = response.data.music;
            let songs = result?.data;
            let pagination = {
              current_page : result?.current_page,
              links: result?.links,
              next_page_url: result?.next_page_url,
              path: result?.path,
              per_page: result?.per_page,
              prev_page_url: result?.prev_page_url,
              to: result?.to,
              total: result?.total,
              last_page_url:result?.last_page_url
            };

            console.log('state....',state.songs);
            commit('SET_PAGINATION',pagination);
            commit('SET_ADDED_SONGS',songs);
            return {status:true}
          })
          .catch(error => {
            console.log(error)
            return {status:false,error:error}
          });
    },
    async fetchMoods({commit}){
      return axios
          .get(constant.MAIN_URL + '/api/moods')
          .then(response => {
            commit('SET_MOODS',response.data)
          })
          .catch(error => {
            console.log(error)
          });
    },
    async fetchTempoLimits({commit}){
      return axios
          .get(constant.MAIN_URL + '/api/tempo-limit')
          .then(response => {
            console.log('limitssss',response.data);
            commit('SET_TEMPO_LIMIT',response.data)
          })
          .catch(error => {
            console.log(error)
          });
    },
    async fetchGenre({commit}){
      return axios
          .get(constant.MAIN_URL + '/api/genres')
          .then(response => {
            //console.log(response.data)
            commit('SET_GENRE',response.data)
          })
          .catch(error => {
            console.log(error)
          });
    },
    async fetchSpecialMoods({commit}){
      return axios
          .get(constant.MAIN_URL + '/api/special_moods')
          .then(response => {
            commit('SET_SPECIAL_MOODS',response.data)
          })
          .catch(error => {
            console.log(error)
          });
    },
  },
}